import { useState, useEffect, useCallback } from "react";
import { createClient } from "urql";
function useFetchIdoCounts() {
  const API_URL = process.env.REACT_APP_SUBGRAPH_API_LATEST_BSC;
  const API_URL_ETH = process.env.REACT_APP_SUBGRAPH_API_LATEST_ETH;

  const [totalIdo, setTotalIdo] = useState({
    live: 0,
    upcoming: 0,
    ended: 0,
  });
  const stateSetter = useCallback(
    (end, live, upcom) => {
      setTotalIdo({
        ...totalIdo,
        ended: end,
        upcoming: upcom,
        live: live,
      });
    },
    [totalIdo]
  );
  const FetchCounts = useCallback(() => {
    async function EffectSolver() {
      var timestamp = await Math.round(+new Date() / 1000);
      const Ended = `query{
        pools (where:{startDate_lt: ${timestamp},  endDate_lt:${timestamp}  , isDeleted:false } ) {
              poolId        
              }   
          }`;

      const Upcoming = `query{
        pools(where:{startDate_gt: ${timestamp},  endDate_gt:${timestamp}, isDeleted:false  } ) {
                poolId        
                }
              
           
            }`;
      const Live = `query{
        pools(where:{startDate_lt: ${timestamp},  endDate_gt:${timestamp} , isDeleted:false   } ) {
                  poolId        
                  }              
              }`;

      const client2 = createClient({
        url: API_URL,
      });
      const clientETH = createClient({
        url: API_URL_ETH,
      });

      const end = await client2.query(Ended).toPromise();
      const endETH = await clientETH.query(Ended).toPromise();

      const upcom = await client2.query(Upcoming).toPromise();
      const upcomETH = await clientETH.query(Upcoming).toPromise();

      const live = await client2.query(Live).toPromise();
      const liveETH = await clientETH.query(Live).toPromise();

      if (
        end.data !== undefined &&
        live.data !== undefined &&
        upcom.data !== undefined &&
        endETH.data !== undefined &&
        upcomETH.data !== undefined &&
        liveETH.data !== undefined
      )
      // if (
      //   end.data !== undefined &&
      //   live.data !== undefined &&
      //   upcom.data !== undefined
      // ) 
      {
        // stateSetter(
        //   end.data.pools.length,
        //   live.data.pools.length,
        //   upcom.data.pools.length
        // );
        stateSetter(
          end.data.pools.length + endETH.data.pools.length,
          live.data.pools.length + liveETH.data.pools.length,
          upcom.data.pools.length + upcomETH.data.pools.length
        );
      }
    }
    EffectSolver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [API_URL, stateSetter]);
  useEffect(() => {
    FetchCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [totalIdo];
}

export default useFetchIdoCounts;
