import React, { useState, useEffect, useCallback } from "react";
import { Row, Container } from "react-bootstrap";
import { ProgressBar } from "react-bootstrap";
import { useCookies } from "react-cookie";
import "./productinfo.css";
import Footer from "../footer";
import Abi from "../../utils/api";
import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import UserInfo from "./UserInfo";
import axios from "axios";
import TokenAbi from "../../utils/token20Abi";
import { createClient } from "urql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DefualtImageSetter } from "../../utils/globalHelpers";
import Alerts from "../Alerts/alert";
import OrderDetail from "../OrderDetails/orderdetail";
import LoaderProductSkeleton from "./productSkeleton";
import moment from "moment";
import LogoEth from "../../assets/images/ethereum.svg";
import IDOClock from "./idoClock";
import ModalLoading from "./loadingModal";

const ProductInfo = () => {
  const { id } = useParams();
  const API_URL = process.env.REACT_APP_SUBGRAPH_API_LATEST_BSC;
  const API_URL_ETH = process.env.REACT_APP_SUBGRAPH_API_LATEST_ETH;
  const [loading, setLoading] = useState(false);
  const [itemId] = useState(id);
  const [item, setItem] = useState({
    idoTokenSymbol: "",
    poolingToken: "",
    poolId: "",
    hardCap: "",
    startDate: "",
    endDate: "",
    price: "",
    totalSupply: "",
    biddingToken: "",
    idoAddress: "",
    logoHash: "",
    infoHash: "",
    isDeleted: "",
    name: "",
    status: "",
    network: "",
    socials: [],
    remainTime: {
      day: " ",
    },
  });
  const [acc, setAcc] = useState("Connect Wallet");
  const [cookies, setCookies, removeCookie] = useCookies(["address"]);
  const [balan, setBal] = useState(0);
  const [biddTokenInfo, setBiddTokenInfo] = useState({
    name: "",
    decimals: 1,
    symbol: "",
  });
  const [totAucStake, setTotAucStake] = useState(0);
  const [stakeAmou, setStakeAmou] = useState(0);
  const [bidPerUser, setBidPerUser] = useState(0);
  const [percen, setPercent] = useState(0);
  const [errors, setErrors] = useState("");
  const [alert, setAlert] = useState("");
  const [approveErr, setApproveErr] = useState("");
  const [stakErr, setStakErr] = useState("");
  const ParseData = async (arg) => {
    const url = await axios.get(`https://ipfs.io/ipfs/${arg}`);

    return url.data;
  };
  const GetRemainDays = async (arg) => {
    var unix = await Math.round(+new Date() / 1000);
    var date1 = unix;
    var date2 = arg;

    // To calculate the time difference of two dates
    var Difference_In_Time = date2 - date1;
    var remainHour = {};
    var days = Math.floor(Difference_In_Time / 86400);
    var hours = Math.floor(days / 60);
    if (days < 0) {
      remainHour.day = 0;
      if (remainHour.hours <= 0) {
        remainHour.hours = 0;
      }
    } else {
      remainHour.day = days;
      remainHour.hours = hours;
    }
    return remainHour;
  };
  const converSubString = (arg) => {
    var res = arg.substring(0, 2);
    var res2 = arg.substring(38, 42);
    const wall = res + "..." + res2;
    return wall;
  };
  const FetchProvider = async (tokenAdd, Abi, isEth = false) => {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum || (isEth? process.env.REACT_APP_TESTNET_RPC_URL_ETH : process.env.REACT_APP_TESTNET_RPC_URL)
    );
    
    let signer = provider.getSigner("0x0000000000000000000000000000000000000000");
    // if(provider.getSigner()._address != null){
      signer = provider.getSigner();
    // }
    
    var address = tokenAdd;
    var contract = await new ethers.Contract(address, Abi, signer);
    return contract;
  };
  const GetBiddingTokenInfo = async (args) => {
    const contract = await FetchProvider(args.biddingToken, TokenAbi);
    const Decimals = parseInt(await contract.decimals());
    const decimalConvert = Math.pow(10, Decimals);
    const name = await contract.name();
    const symbol = await contract.name();
    setBiddTokenInfo({
      ...biddTokenInfo,
      name: name,
      decimals: decimalConvert,
      symbol: symbol,
    });
    return decimalConvert;
  };
  const CheckApprove = useCallback(
    async (args) => {
      /*BALANCE SET*/
      const contract = await FetchProvider(args.biddingToken, TokenAbi);
      var balanPool = await contract.balanceOf(cookies.address);
      const decimal = await GetBiddingTokenInfo(args);
      const balDeci = (balanPool / decimal).toFixed(3);
      setBal(balDeci);
      /*BALANCE SET*/

      const allowance = await contract.allowance(
        cookies.address,
        args.idoAddress
      );

      if (parseInt(allowance.toString()) <= 0) {
        // if not allowance then go for approve
        setApproveErr("Wait ! it takes some time for Approval");
      } else {
        setApproveErr("");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cookies.address]
  );
  const GetTotalStakeAmout = useCallback(
    async (args) => {
      const { idoAddress, totalSupply, biddingToken } = args;

      const tokenAdd = idoAddress;

      // Get bidding decimal here

      const biddingTokenContract = await FetchProvider(biddingToken, TokenAbi);

      const Decimals = parseInt(await biddingTokenContract.decimals());
      const arg = {
        biddingToken,
        idoAddress,
      };
      CheckApprove(arg);
      const decimalConvert = Math.pow(10, Decimals);
      // Get bidding decimal here

      //  Get total purchase Amount per User
      const contract = await FetchProvider(tokenAdd, Abi);

      const totalBidding = parseInt(await contract.totalPurchasedAmount());
      if (cookies.address !== undefined) {
        const purchasedAmouPerUser = parseFloat(
          await contract.purchasedAmounts(cookies.address)
        );

        const newPurchaseAmount =
          purchasedAmouPerUser / parseInt(decimalConvert);

        setBidPerUser(newPurchaseAmount);
      }

      const divWithDecimals = totalBidding / decimalConvert;
      // const result = exponentialToDecimal(divWithDecimals)

      setTotAucStake(divWithDecimals);

      const divTotalBid = totalBidding / decimalConvert;
      const perc = ((divTotalBid / totalSupply) * 100).toFixed(2);
      setPercent(perc);
      //  Get total purchase Amount per User
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cookies.address]
  );
  const FetchDataSetter = useCallback(
    async (idoData) => {
      // Data Parsing and Conversion appear here
      const hashData = await ParseData(idoData.infoHash);

      const {
        title,
        description,
        poolingToken,
        poolingTokenDecimal,
        biddingToken,
        tokenPrice,
        isKyc,
        logo,
        twitter,
        discord,
        medium,
        networks,
        poolStartDate,
        telegram,
        website,
      } = hashData;

      const { ethereum } = window;
      if (hashData.networks === "ETH") {
        try {
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x5" }],
          });
        } catch (addError) {
          console.error("error in switching network", addError);
        }
      } else if (hashData.networks === "BSC") {
        try {
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x61" }],
          });
        } catch (addError) {
          console.error("error in switching network", addError);
        }
      }


      const contract = await FetchProvider(poolingToken, TokenAbi,(hashData.networks === "ETH"));
      const symbol = await contract.name();

      var poolStatus = "";
      var unix = Math.round(+new Date() / 1000);
      if (unix < idoData.startDate && unix < idoData.endDate) {
        poolStatus = "upcoming";
      } else if (unix >= idoData.endDate) {
        poolStatus = "close";
      } else if (unix > idoData.startDate && unix < idoData.endDate) {
        poolStatus = "open";
      }

      const tempEnd = moment
        .unix(idoData.startDate)
        .format("MMMM DD YYYY, h:mm:ss a");
      const timinEnd = moment
        .unix(idoData.endDate)
        .format("MMMM Do YYYY, h:mm:ss a");
      const remainHour = await GetRemainDays(idoData.endDate);

      var newTotalRaised =
        idoData.totalRaised /
        Math.pow(10, parseInt(idoData.poolingTokenDecimal));

      var newPrice =
        parseInt(idoData.price) /
        parseInt(Math.pow(10, parseInt(idoData.poolingTokenDecimal)));

      let dummyObj = {
        poolId: idoData.poolId,
        symbol: symbol,
        startDate: tempEnd,
        endDate: timinEnd,
        price: tokenPrice,
        idoAddress: idoData.poolingToken,
        totalSupply: newTotalRaised,
        hardCap: (newTotalRaised * newPrice).toFixed(3),
        name: title,
        logo: logo,
        description: description,
        biddingToken: biddingToken,
        poolingToken: poolingToken,
        socials: [twitter, discord, medium, telegram, website],
        decimals: poolingTokenDecimal,
        remainTime: remainHour,
        isKyc: isKyc.toString(),
        poolStatus: poolStatus,
        timeCounter: poolStartDate,
        network: networks,
      };
      setItem(dummyObj);
      if (cookies.address !== undefined) {
        await CheckApprove(dummyObj);
        GetTotalStakeAmout(dummyObj);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [GetTotalStakeAmout, CheckApprove]
  );
  const fetchWithId = useCallback(
    async (id) => {
      var str = id;


      str = str.replace(":", "");

      const tokensQuery = `{
        pools (where :{ poolingToken:"${str}" }) 
      
      {
        name
        startDate
        poolingTokenDecimal
        endDate
        price
        totalRaised
        poolingToken
        logoHash
        isDeleted
        poolId
        infoHash
        blockTimestamp
        transactionHash
        network
        twitter
        discord
        medium
        telegram
        website
      }
      
        
      }`;

      const client2 = createClient({
        url: API_URL,
      });

      const data2 = await client2.query(tokensQuery).toPromise();
      const clientETH = createClient({
        url: API_URL_ETH,
      });
      const dataETH = await clientETH.query(tokensQuery).toPromise();

      if (data2.data.pools.length > 0) {
        if (
          data2.data.pools !== null &&
          data2.data.pools !== undefined &&
          data2.data.pools[0].network === "BSC"
        ) {
          FetchDataSetter(data2.data.pools[0]);
        }
      } 
      if (dataETH.data.pools.length > 0) {
        if(
          dataETH.data.pools !== null &&
          dataETH.data.pools !== undefined &&
          dataETH.data.pools[0].network === "ETH"
        ){
          FetchDataSetter(dataETH.data.pools[0]);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [API_URL, FetchDataSetter]
  );
  useEffect(() => {
    if (window.ethereum !== undefined) {
      window.ethereum.on("accountsChanged", (accounts) => {
        let selectedAccount = accounts[0];
        setCookies("address", selectedAccount);
        setAcc(converSubString(selectedAccount));
      });
      if (cookies.address !== undefined) {
        setAcc(converSubString(cookies.address));
      }

      if (cookies.address === "undefined") {
        removeCookie("address");
        setAcc("Connect Wallet");
      }
    }
    fetchWithId(itemId);
  }, [
    cookies.address,
    acc,
    setCookies,
    removeCookie,
    fetchWithId,
    itemId,
    GetTotalStakeAmout,
  ]);
  const handleChange = (e) => {
    const remainAucAmount = item.totalSupply - totAucStake;
    if (
      item.totalSupply < e.target.value ||
      remainAucAmount < e.target.value ||
      e.target.value < 0
    ) {
      setErrors("Enter Valid Amount");
    } else {
      setErrors("");
    }
    setStakeAmou(e.target.value);
  };
  const handleApprove = async () => {
    const contract = await FetchProvider(item.biddingToken, TokenAbi);
    const approve = await contract.approve(
      item.idoAddress,
      "115792089237316195423570985008687907853269984665640564039457584007913129639935"
    );
    await approve.wait();
    setApproveErr("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const handleStakeClick = async () => {
    if (cookies.address !== undefined) {
      if (
        parseFloat(stakeAmou) <= 0 ||
        // stakeAmou < parseInt(item.minBuyAmount) ||
        parseFloat(stakeAmou) > parseFloat(balan)
      ) {
        setErrors(`You dont have Enough ${biddTokenInfo.name} `);
      } else {
        setErrors("");

        setStakErr("");
        const tokenAdd = item.idoAddress;
        const contract = await FetchProvider(tokenAdd, Abi);
        // const stakeWithDecimal = stakeAmou * tokenInf.decimal

        const stakeWithBid = (
          stakeAmou * parseInt(biddTokenInfo.decimals)
        ).toString();

        setLoading(true);
        contract
          .purchase(stakeWithBid)
          .then(async (res) => {
            await res.wait();
            setLoading(false);
            await GetTotalStakeAmout(item);
          })
          .catch((err) => {
            setLoading(false);
            if (err.error.message.indexOf("CALLER_NO_WHITELIST") >= 0) {
              AlertNotify(`😢 You aren't whitelisted to stake in this pool !`, 4000);
            } else {
              AlertNotify(`😭 Error in staking tokens`, 4000);
            }
          });

        setApproveErr("");
      }
    } else {
      AlertNotify("Connect Your MetaMask", 4000);
    }
  };
  const AlertNotify = (message, time) => {
    setAlert(message);

    setTimeout(() => {
      setAlert("");
    }, time);
  };

  return (
    <>
      <section className="section_padding" id="productInfo">
        {item.poolId !== "" ? (
          <Container>
            {alert !== "" ? <Alerts message={alert} show={true} /> : <></>}
            <Row>
              <div className="col-lg-4 mb-lg-0 mb-3">
                <div className="profile-info">
                  <div className="top-sec ">
                    <div className="profile">
                      <img
                        src={`https://ipfs.io/ipfs/${item.logo}`}
                        alt="Profile img"
                        onError={DefualtImageSetter}
                      />
                    </div>
                    <div className="name">
                      <h2>{item.name}</h2>
                      <div className="text-sm font-secondary text-ignored">
                        {item.symbol} / {biddTokenInfo.symbol}
                      </div>
                    </div>
                  </div>
                  <div className="badges">
                    <span className="outerside soon">Soon</span>
                    <span className="outerside level">level</span>
                    {item.isKyc === "true" ? (
                      <span className="outerside kyc">KYC</span>
                    ) : (
                      <></>
                    )}{" "}
                  </div>
                  <div className="wallet-sec text-center">
                    {item.poolStatus === "close" ? (
                      <>
                        <h4 className="text-white mt-2">Sale Ended</h4>
                      </>
                    ) : item.poolStatus === "upcoming" ? (
                      <>
                        <h4 className="text-white mt-3">
                          Sale Starts on
                          <IDOClock date1={item.startDate} />
                        </h4>
                      </>
                    ) : (
                      <>
                        <div className="input-wrapper justify-content-center">
                          {approveErr === "" ? (
                            <>
                              <div className="slide_text_code">
                                <div className="d-flex">
                                  <div className="filed-wrapper stake-wrap mb-2">
                                    <input
                                      type="number"
                                      onChange={handleChange}
                                      value={stakeAmou}
                                      placeholder="Enter value to Stake"
                                      name="stake-amou"
                                      className="input-field text-white"
                                      required
                                    />
                                  </div>
                                  {errors === "" ? (
                                    <button
                                      className="light-blue-btn my-0"
                                      onClick={handleStakeClick}
                                    >
                                      Stake
                                    </button>
                                  ) : (
                                    <button
                                      className="light-blue-btn my-0"
                                      disabled={true}
                                    >
                                      Stake
                                    </button>
                                  )}
                                </div>
                                <div className="slide_text w-100">
                                  <span>
                                    {stakeAmou} {item.symbol} ={" "}
                                    {(stakeAmou * item.price).toFixed(4)}{" "}
                                    {biddTokenInfo.symbol}{" "}
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="slide_text_code">
                                <div className="d-flex">
                                  <div className="filed-wrapper stake-wrap mb-2">
                                    <input
                                      type="number"
                                      value={""}
                                      placeholder="Approve Your Tokens"
                                      name="approveAmou"
                                      disabled={true}
                                      className="input-field text-white"
                                      required
                                    />
                                  </div>
                                  <button
                                    className="light-blue-btn my-0"
                                    onClick={handleApprove}
                                  >
                                    Approve
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <UserInfo
                          item={item.biddingToken}
                          bal={balan}
                          idoAddress={item.idoAddress}
                          bidName={biddTokenInfo.name}
                        />
                      </>
                    )}
                    {errors !== "" ? (
                      <div className="text-danger p-1">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <span className="ml-2 ms-2">{errors}</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="usd ">
                    <h3 className="mt-4">
                      {" "}
                      1 {item.symbol} = {item.price} {biddTokenInfo.symbol}{" "}
                    </h3>
                    <h4 className="mt-2">
                      {" "}
                      {item.price} {biddTokenInfo.symbol} = 1 {item.symbol}{" "}
                    </h4>
                  </div>
                  <div className="progress-bar">
                    <div className="prog-text d-flex">
                      <span style={{ color: "white" }}>
                        Close in {item.remainTime.day} Days,{" "}
                        {item.remainTime.hours} hours
                      </span>
                      <span style={{ color: "white" }}>{percen}%</span>
                    </div>
                    <ProgressBar now={percen} />
                    <div className="prog-text d-flex ">
                      {/* <span>{totBidStake} BUSD</span> */}
                      <span className="pt-2" style={{ color: "white" }}>
                        {totAucStake} {item.symbol} /
                        {item.totalSupply.toFixed(2)} {item.symbol}
                      </span>
                    </div>
                    {stakErr !== "" ? (
                      <div>
                        <p className="text-danger p-1">{stakErr}</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="wifi text-white">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 640 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M634.91 154.88C457.74-8.99 182.19-8.93 5.09 154.88c-6.66 6.16-6.79 16.59-.35 22.98l34.24 33.97c6.14 6.1 16.02 6.23 22.4.38 145.92-133.68 371.3-133.71 517.25 0 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.97c6.43-6.39 6.3-16.82-.36-22.98zM320 352c-35.35 0-64 28.65-64 64s28.65 64 64 64 64-28.65 64-64-28.65-64-64-64zm202.67-83.59c-115.26-101.93-290.21-101.82-405.34 0-6.9 6.1-7.12 16.69-.57 23.15l34.44 33.99c6 5.92 15.66 6.32 22.05.8 83.95-72.57 209.74-72.41 293.49 0 6.39 5.52 16.05 5.13 22.05-.8l34.44-33.99c6.56-6.46 6.33-17.06-.56-23.15z"></path>
                    </svg>
                    <span>IDO and distribution on BSC</span>
                  </div>

                  <div className="d-sm-flex text-white justify-content-between ">
                    <div className="sale mb-2">
                      <span>User Buy Amount</span>
                    </div>
                    <span>{bidPerUser}</span>
                  </div>

                  <div className="d-sm-flex text-white justify-content-between ">
                    <div className="sale">
                      <span>Sale</span>
                    </div>
                    <span>{item.startDate}</span>
                  </div>

                  {item.poolStatus === "close" &&
                  cookies.address !== undefined ? (
                    <OrderDetail
                      idoAddress={item.idoAddress}
                      ownerAddress={cookies.address}
                      bidDecimal={item.biddingToken}
                      poolDecimal={item.decimals}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-lg-8 mb-lg-0 mb-3">
                <div className="coin-detail text-white">
                  <div className="d-flex flex-sm-row flex-column align-items-center justify-content-between">
                    <div className="coin-profile d-flex align-items-center">
                      <img
                        className="me-2"
                        src={`https://ipfs.io/ipfs/${item.logo}`}
                        alt="Profile img"
                        onError={DefualtImageSetter}
                      />
                      <h2 className="mb-0 ms-1">{item.name}</h2>
                    </div>

                    <div className="mb-sm-0 mb-2">
                      <img
                        src={
                          item.network === "BSC"
                            ? "https://dex-bin.bnbstatic.com/static/images/logo_BNB_Chain.svg"
                            : LogoEth
                        }
                        className="chainLogo"
                        alt="network logo"
                      ></img>
                    </div>
                  </div>
                  <br />
                  <p>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </p>
                  <br />
                  <div className="social-icons">
                    <ul className="mb-0">
                      {item.socials[0] && (
                        <li>
                          <a
                            href={item.socials[0]}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 512 512"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                            </svg>
                          </a>
                        </li>
                      )}
                      {item.socials[1] && (
                        <li>
                          <a
                            href={item.socials[1]}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 276 212"
                              height="30px"
                              width="30px"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"></path>
                            </svg>
                          </a>
                        </li>
                      )}
                      {item.socials[2] && (
                        <li>
                          <a
                            href={item.socials[2]}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 56 50"
                              height="30px"
                              width="30px"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M2,40.2l5.3-6.1v-21L2.6,7.8V7H15.1l10,21.2L33.9,7H46v.8l-4,3.7V36.6l4,3.6V41H28.6v-.8l4.1-4.8V16.6L22.7,41H21.4L9.8,17.1V33.9l5.3,6.3V41H2Z"></path>
                            </svg>
                          </a>
                        </li>
                      )}
                      {item.socials[3] && (
                        <li>
                          <a
                            href={item.socials[3]}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 448 512"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path>
                            </svg>
                          </a>
                        </li>
                      )}
                      {item.socials[4] && (
                        <li className="mx-0">
                          <a
                            href={item.socials[4]}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 496 512"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path>
                            </svg>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="pool-detail">
                    <h4>Pool Details</h4>

                    <ul>
                      <li className="d-sm-flex">
                        <div className="w-50">
                          <span className="title">Hard Cap: </span>
                          <span> ${item.hardCap}</span>
                        </div>
                      </li>
                      <li>
                        <span className="title">Swap Rate: </span>
                        <span className="desc-color">
                          1 {item.symbol}
                          <span className="text-white"> = </span> ${item.price}{" "}
                          | {item.price} {item.symbol}
                          <span className="text-white"> per </span> {item.price}{" "}
                          {biddTokenInfo.name}
                        </span>
                      </li>
                      <li className="mb-0">
                        <span className="title">Start/End: </span>
                        <span>
                          {item.startDate} UTC - {item.endDate} UTC
                        </span>
                      </li>
                    </ul>
                    <h4>Token</h4>
                    <ul>
                      <li>
                        <span className="title">Token: </span>
                        <span className="desc-color">
                          {" "}
                          {item.name}({item.symbol})
                        </span>
                      </li>
                      <li className="mb-0">
                        <span className="title">Token Listing: </span>
                        <span> TBA</span>
                      </li>
                    </ul>
                    <h4>Distribution</h4>
                    <ul className="mb-0">
                      <li>
                        <span className="title">Distribution: </span>
                        <span> Claimed on MutoPad</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {loading ? <ModalLoading /> : <></>}
            </Row>
          </Container>
        ) : (
          <>
            <LoaderProductSkeleton />
          </>
        )}
      </section>
      <Footer />
    </>
  );
};
export default ProductInfo;
