import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { Button, Badge, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faExternalLinkAlt,
  faTimes,
  faCheck,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { TailSpin } from "react-loader-spinner";
import useFetchAllOrder from "../../../CustomHooks/FetchAllOrder";
import Createpool from "../../CreatePool/createpool";
import StorageAbi from "../../../utils/StorageAbi";
import IDO_ABI from "../../../utils/newIdoAbi";
import { ethers } from "ethers";
import Alerts from "../../Alerts/alert";

function ManagePools() {
  const [AllOrders] = useFetchAllOrder();
  const [addPool, setAddPool] = useState(false);
  const [alert, setAlert] = useState("");
  const [show, setShow] = useState(false);
  const [whitelister, setWhitelister] = useState(null);
  const [inputWhitelistAddress, setInputWhitelistAddress] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = async (e) => {
    const { ethereum } = window;

    try {
      if (e.network === "ETH") {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x5" }],
        });
      } else if (e.network === "BSC") {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x61" }],
        });
      }
    } catch (err) {
      console.error("error in switching network", err.message);
    }

    const contractIdo = await FetchProvider(e.poolingToken, IDO_ABI);
    let whitelistedUsers = await contractIdo.whitelistedUsers();
    whitelistedUsers = whitelistedUsers.filter(
      (item) => item !== "0x0000000000000000000000000000000000000000"
    );

    setWhitelister({ poolingToken: e.poolingToken, list: whitelistedUsers });
    setShow(true);
  };

  const addWhiteListToPoll = async (e) => {
    const contractIdo = await FetchProvider(e.poolingToken, IDO_ABI);

    if (!ethers.utils.isAddress(inputWhitelistAddress)) {
      window.alert(`Invalid or incomplete address`);
      return;
    }

    const transaction = await contractIdo.addWhitelist([inputWhitelistAddress]);
    const receipt = await transaction.wait();

    if (receipt) {
      AlertNotify(`Address is whitelisted successfully`, 3000);
      setShow(false);
    }
    setInputWhitelistAddress("");
  };

  const removeWhiteListToPoll = async (e, addressToRemove) => {
    const contractIdo = await FetchProvider(e.poolingToken, IDO_ABI);

    if (!ethers.utils.isAddress(addressToRemove)) {
      window.alert(`Invalid or incomplete address`);
      return;
    }

    const transaction = await contractIdo.removeWhitelist([addressToRemove]);
    const receipt = await transaction.wait();

    if (receipt) {
      AlertNotify(`Address is removed from whitelist`, 3000);
      setShow(false);
    }
    setInputWhitelistAddress("");
  };

  useEffect(() => {}, [alert]);
  const FetchProvider = async (tokenAdd, Abi) => {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum || process.env.REACT_APP_TESTNET_RPC_URL
    );
    const signer = provider.getSigner();

    if (ethers.utils.isAddress(tokenAdd)) {
      var address = tokenAdd;
      var contract = new ethers.Contract(address, Abi, signer);
      return contract;
    } else {
    }
  };

  const handleEnable = async (e) => {
    const { ethereum } = window;
    if (e.network === "ETH") {
      try {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x5" }],
        });
        const StorageAddress = process.env.REACT_APP_STORAGE_ADDRESS_ETH;
        const contract = await FetchProvider(StorageAddress, StorageAbi);

        const enable = !e.isDeleted;

        const statusChnage = await contract.setIsDeleted(
          parseInt(e.poolId),
          enable
        );

        const receipt = await statusChnage.wait();

        if (receipt) {
          AlertNotify(`Successfully change Pool Status`, 3000);
          window.location.href =
            "https://mutopad.server18.arhamsoft.info/admin/managepools";
        }
      } catch (addError) {
        // console.error("error in switching network", addError);
      }
    } else if (e.network === "BSC") {
      try {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x61" }],
        });
        const StorageAddress = process.env.REACT_APP_STORAGE_ADDRESS_BSC;
        const contract = await FetchProvider(StorageAddress, StorageAbi);
        const enable = !e.isDeleted;
        const statusChnage = await contract.setIsDeleted(
          parseInt(e.poolId),
          enable
        );
        const receipt = await statusChnage.wait();
        if (receipt) {
          AlertNotify(`Successfully change Pool Status`, 3000);
          window.location.href =
            "https://mutopad.server18.arhamsoft.info/admin/managepools";
        }
      } catch (addError) {
        // console.error("error in switching network", addError);
      }
    }
  };

  const handleCloseAddPool = () => {
    setAddPool(false);
  };

  const AlertNotify = (message, time) => {
    setAlert(message);

    setTimeout(() => {
      setAlert("");
    }, time);
  };
  return (
    <>
      <div className="mutopad-w-100">
        <div className="content">
          <div className="manage-pools-pg">
            {alert !== "" ? <Alerts message={alert} show={true} /> : <></>}
            {addPool ? (
              <>
                <Card className="pt-lg-3 pt-3 h-auto" id="style-6">
                  <Card.Header className="titles-sett">
                    <Createpool handleCloseAddPool={handleCloseAddPool} />
                  </Card.Header>
                </Card>
              </>
            ) : (
              <Card className="pt-lg-4 pt-3 h-auto" id="style-6">
                <Card.Header className="titles-sett">
                  <h2 className="text-shadow">Manage Pools</h2>
                  <div className="sm-right-btn">
                    <Button
                      className="d-inline light-blue-btn text-capitalize"
                      onClick={() => setAddPool(true)}
                    >
                      <FontAwesomeIcon className="add-icon" icon={faPlus} /> Add
                      Pool
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  {AllOrders.length > 0 ? (
                    <>
                      <div className="table-responsive manage-pools">
                        <Table>
                          <thead>
                            <tr>
                              {/* <th>Pool #</th> */}
                              <th>Pool</th>
                              <th>Name</th>
                              <th>Network</th>
                              <th>Start</th>
                              <th>End</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {AllOrders.map((item, index) => {
                              return (
                                <tr key={index}>
                                  {/* <td>{item.poolId}</td> */}
                                  <td>
                                    {" "}
                                    <Link
                                      to={`/pool/${item.poolingToken}`}
                                      style={{ color: "white" }}
                                    >
                                      <FontAwesomeIcon
                                        className="del-icon"
                                        icon={faExternalLinkAlt}
                                      />{" "}
                                      0x...{item.poolingToken.substring(39, 42)}
                                    </Link>
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.network}</td>
                                  <td>{item.startDate}</td>
                                  <td>{item.endDate}</td>
                                  <td>
                                    {item.isDeleted === false ? (
                                      <Badge className="px-2 py-1" bg="info">
                                        Active
                                      </Badge>
                                    ) : (
                                      <Badge
                                        className="px-2 py-1"
                                        bg="secondary"
                                      >
                                        Disabled
                                      </Badge>
                                    )}{" "}
                                  </td>
                                  <td>
                                    <button
                                      className="del_btn"
                                      onClick={() => {
                                        handleEnable(item);
                                      }}
                                    >
                                      {item.isDeleted === false ? (
                                        <>
                                          <FontAwesomeIcon
                                            className="del-icon"
                                            icon={faTimes}
                                          />
                                          &nbsp;&nbsp;<u>Disable this Pool</u>
                                        </>
                                      ) : (
                                        <>
                                          <FontAwesomeIcon
                                            className="del-icon"
                                            icon={faCheck}
                                          />
                                          &nbsp;&nbsp;<u>Enable this Pool</u>
                                        </>
                                      )}
                                    </button>
                                    <Button
                                      className="del_btn aa"
                                      onClick={() => {
                                        handleShow(item);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        className="del-icon"
                                        icon={faUserAlt}
                                      />
                                      &nbsp;&nbsp;<u>Manage Kyc</u>
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <div className="text-center faq-loader">
                      <TailSpin
                        height="100"
                        width="100"
                        color="#46bdf4"
                        ariaLabel="loading"
                      />
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="cms-pages-style"
      >
        <Modal.Header closeButton className="faq-modal">
          <Modal.Title>Manage KYC</Modal.Title>
          <button
            type="button"
            className="close-btn"
            onClick={handleClose}
            aria-label="Close"
          >
            ×
          </button>
        </Modal.Header>
        <Modal.Body className="faq-modal">
          <Form className="form-border">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <Form.Group className="w-100 me-3" controlId="WhiteListAddress">
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Enter new wallet to whitelist"
                  onChange={(e) => {
                    setInputWhitelistAddress(e.target.value);
                  }}
                  required
                />
              </Form.Group>
              <Button
                variant="primary"
                className="blue-imp light-blue-btn text-capitalize btn btn-primary m-0"
                onClick={() => {
                  addWhiteListToPoll(whitelister);
                }}
              >
                ADD
              </Button>
            </div>
          </Form>

          <div className="table-responsive manage-pools">
            <Table>
              <thead>
                <tr>
                  <th>Address</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {whitelister && whitelister.list.length > 0 ? (
                  whitelister.list.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>{item}</td>
                          <td className="text-center">
                            <Button
                              variant="primary"
                              className="blue-imp light-blue-btn text-capitalize btn btn-primary"
                              onClick={() => {
                                removeWhiteListToPoll(whitelister, item);
                              }}
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td align="center" colSpan={2}>
                        No whitelister added yet!
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ManagePools;
