import React, { useCallback, useState, useEffect } from "react";
import "./landing.css";

import axios from "axios";
import { Nav, Tab } from "react-bootstrap";
import { createClient } from "urql";
import IdoCards from "./IdoCards";
import useFetchIdoCounts from "../../CustomHooks/FetchIdoCounts";

// import useFetchGraph from '../../CustomHooks/FetchGraph'
import LoaderCardSkeleton from "./loadercardskeleton";
// import { ethers } from 'ethers'
const MultiChainCards = () => {
  //Custom hook for fetching data from subgraph
  const API_URL = process.env.REACT_APP_SUBGRAPH_API_LATEST_BSC;
  const API_ETH = process.env.REACT_APP_SUBGRAPH_API_LATEST_ETH;

  const [itemPerPage, setItemPerPage] = useState(0);
  const [time] = useState({});

  const [totalIdo] = useFetchIdoCounts();
  const [idoInfo, setIdoInfo] = useState([]);
  const timeConverter = useCallback(
    (UNIX_timestamp) => {
      // var a = new Date(UNIX_timestamp * 1000);
      var a = new Date(UNIX_timestamp * 1000);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      // var sec = a.getSeconds()

      var time1 = date + " " + month + " " + year;
      time.month = month;
      time.hour = hour;
      time.min = min;
      time.date = date;
      return time1;
    },
    [time]
  );
  const ParseData = async (arg) => {
    let url = '';
    try{
      url = await axios.get(`https://ipfs.io/ipfs/${arg}`);
      url = url.config.url;
    } catch (err) {
      url = process.env.REACT_APP_DEFAULT_LOGO_FOR_POOLS;
    }
    
    return url;
  };
  const GetRemainDays = async (arg) => {
    var unix = await Math.round(+new Date() / 1000);
    var date1 = unix;
    var date2 = arg;

    // To calculate the time difference of two dates
    var Difference_In_Time = date2 - date1;
    var remainHour = {};
    var days = Math.floor(Difference_In_Time / 86400);
    var hours = Math.floor(days / 60);
    if (days < 0) {
      remainHour.day = 0;
      remainHour.hours = 0;
    } else {
      remainHour.day = days;
      remainHour.hours = hours;
    }
    return remainHour;
  };
  const GetTotalStakeAmout = async (token, raised) => {
    let perc = Math.floor(Math.random() * 100);
    return perc;
  };
  const FetchDataSetter = useCallback(
    (idoData) => {
      async function FetchHelper(idoData) {
        let dummyArr = [];
        // Data Parsing and Conversion appear here
        if(idoData !== undefined){
        for (let i = 0; i < idoData.length; i++) {
          const {
            poolId,
            totalRaised,
            poolingToken,
            poolingTokenDecimal,
            startDate,
            endDate,
            price,
            name,
            logoHash,
            socials,
          } = idoData[i];
          await Math.round(+new Date() / 1000);
          const timinStart = timeConverter(startDate);
          const timinEnd = timeConverter(endDate);

          const remainHour = await GetRemainDays(endDate);
          var hashData;
          if (logoHash === "") {
            hashData = process.env.REACT_APP_DEFAULT_LOGO_FOR_POOLS;
          } else {
            hashData = await ParseData(logoHash);
          }

          var newTotalRaised =
            parseInt(totalRaised) / parseInt(Math.pow(10, parseInt(poolingTokenDecimal)));
          var newPrice =
            parseInt(price) / parseInt(Math.pow(10, parseInt(poolingTokenDecimal)));

          const percentFilled = await GetTotalStakeAmout(
            poolingToken,
            newTotalRaised
          );

          let dummyObj = {
            poolId: poolId,
            totalRaised: newTotalRaised.toFixed(2),
            idoAddress: poolingToken,
            startDate: timinStart,
            endDate: timinEnd,
            price: newPrice,
            name: name,
            logoHash: hashData,
            socials: socials,
            remainingHours: remainHour,
            salePercent: percentFilled,
          };
          dummyArr.push(dummyObj);
          setIdoInfo((AllOrders) => [...AllOrders, dummyObj]);
        }
      }
      }

      FetchHelper(idoData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeConverter, idoInfo]
  );
  const fetchLiveIdo = async () => {
    var timestamp = await Math.round(+new Date() / 1000);

    const tokensQuery = `{
      
      pools(orderBy:poolId orderDirection:asc, skip:${itemPerPage}, where:{startDate_lt: ${timestamp},  endDate_gt:${timestamp},isDeleted:false}){
      poolingToken
      poolingTokenDecimal
      poolId
      totalRaised
      startDate
      endDate
      price
      name
      logoHash
      infoHash
      isDeleted
      name
      twitter
      discord
      medium
      telegram
      website
      network
      }
   
 }`;

    const client2 = createClient({
      url: API_URL,
    });

    const data2 = await client2.query(tokensQuery).toPromise();

    const clientETH = createClient({
      url: API_ETH,
    });

    const dataETH = await clientETH.query(tokensQuery).toPromise();

    if (data2.data !== null && data2.data !== undefined) {
      FetchDataSetter(data2.data.pools);
      // setIdoInfo(data2.data.pools)
    }
    if (dataETH.data !== null && dataETH.data !== undefined) {
      FetchDataSetter(dataETH.data.pools);
      // setIdoInfo(data2.data.pools)
    }
  };
  useEffect(() => {
    fetchUpcomingIdo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUpcomingIdo = async () => {
    var timestamp = await Math.round(+new Date() / 1000);
    const tokensQuery = `{
      pools(orderBy:poolId, orderDirection:asc,  skip:${itemPerPage}, where:{startDate_gt: ${timestamp},  endDate_gt:${timestamp} ,isDeleted:false } )
      {
        poolingToken
        poolingTokenDecimal
        poolId
        totalRaised
        startDate
        endDate
        price
        name
        logoHash
        infoHash
        isDeleted
        name
        twitter
        discord
        medium
        telegram
        website
        network
      }
    }`;

    const client2 = createClient({
      url: API_URL,
    });
    const data2 = await client2.query(tokensQuery).toPromise();
    const clientETH = createClient({
      url: API_ETH,
    });
    const dataETH = await clientETH.query(tokensQuery).toPromise();

    if (data2.data !== null && data2.data !== undefined) {
      FetchDataSetter(data2.data.pools);
      // setIdoInfo(data2.data.pools)
    }
    if (dataETH.data !== null && dataETH.data !== undefined) {
      FetchDataSetter(dataETH?.data.pools);
      // setIdoInfo(data2.data.pools)
    }

  };
  const fetchEndedIdo = async () => {
    var timestamp = await Math.round(+new Date() / 1000);
    const tokensQuery = `{
      pools (orderBy:poolId, orderDirection:asc,  skip:${itemPerPage}, where:{startDate_lt: ${timestamp},  endDate_lt:${timestamp}, isDeleted:false } ) {
        poolingToken
        poolingTokenDecimal
        poolId
        totalRaised
        startDate
        endDate
        price
        name
        logoHash
        infoHash
        isDeleted
        name
        twitter
        discord
        medium
        telegram
        website
        network
      }
    }`;
    const client2 = createClient({
      url: API_URL,
    });

    const data2 = await client2.query(tokensQuery).toPromise();
    const clientETH = createClient({
      url: API_ETH,
    });

    const dataETH = await clientETH.query(tokensQuery).toPromise();

    if (data2.data !== null || data2.data !== undefined) {
      FetchDataSetter(data2.data.pools);
      FetchDataSetter(dataETH.data.pools);
    }
  };

  const handleUpcoming = () => {
    setIdoInfo([]);
    idoInfo.length = 0;
    setItemPerPage(0);
    fetchUpcomingIdo();
  };
  const handleLive = () => {
    setIdoInfo([]);
    idoInfo.length = 0;
    setItemPerPage(0);
    fetchLiveIdo();
  };
  const handleEnded = () => {
    setIdoInfo([]);
    idoInfo.length = 0;
    setItemPerPage(0);
    fetchEndedIdo();
  };

  return (
    <>
      <section className="tab-cards">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="container-fluid custom-block">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link
                  name="first"
                  eventKey="first"
                  onClick={handleUpcoming}>
                  Upcoming ({totalIdo.upcoming})
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link name="second" eventKey="second" onClick={handleLive}>
                  Live({totalIdo.live})
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link name="third" eventKey="third" onClick={handleEnded}>
                  Ended ({totalIdo.ended})
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <>
                <Tab.Pane eventKey="first">
                  {totalIdo.upcoming > 0 && idoInfo.length <= 0 ? (
                    <>
                      <LoaderCardSkeleton />
                    </>
                  ) : totalIdo.upcoming > 0 ? (
                    <>
                      <IdoCards
                        idoArr={idoInfo}
                        currentIdoLength={totalIdo.upcoming}
                      />
                    </>
                  ) : (
                    <div className="text-white d-flex justify-content-center align-items-center">
                      <h4>No Upcoming IDO's</h4>
                    </div>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  {totalIdo.live > 0 && idoInfo.length <= 0 ? (
                    <>
                      <LoaderCardSkeleton />
                    </>
                  ) : totalIdo.live > 0 && idoInfo.length > 0 ? (
                    <>
                      <IdoCards
                        idoArr={idoInfo}
                        currentIdoLength={totalIdo.live}
                      />
                    </>
                  ) : (
                    <div className="text-white d-flex justify-content-center align-items-center">
                      <h4>No Upcoming IDO's</h4>
                    </div>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  {totalIdo.ended > 0 && idoInfo.length <= 0 ? (
                    <>
                      {" "}
                      <LoaderCardSkeleton />
                    </>
                  ) : totalIdo.ended > 0 ? (
                    <>
                      <IdoCards
                        idoArr={idoInfo}
                        currentIdoLength={totalIdo.ended}
                      />
                    </>
                  ) : (
                    <div className="text-white d-flex justify-content-center align-items-center">
                      <h4>No Upcoming IDO's</h4>
                    </div>
                  )}
                </Tab.Pane>
              </>
            </Tab.Content>
          </div>
        </Tab.Container>
      </section>
    </>
  );
};

export default MultiChainCards;
