import React, { useEffect, useCallback, useState } from 'react'
import { useCookies } from 'react-cookie'

const UserInfo = props => {
  const [dataProps, setDataProps] = useState({
    BidUser: props.bidPerUser
  })
  const [cookies] = useCookies(['address'])

  const fetchBal = useCallback(async () => {
    setDataProps({
      ...dataProps,
      BidUser: props.bidPerUser
    })
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.address, props.bidPerUser])

  useEffect(() => {}, [cookies.address, fetchBal, props.item, dataProps])

  return (
      <div className='mt-2'>
        <p className='wallet_blnc'>
          <span className='text-capitalize text-white'>balance:</span> <span>{props.bal} {props.bidName}{' '}</span>
        </p>

     
    </div>
  )
}
export default UserInfo
