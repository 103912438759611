import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/MutoPad.svg';
import quil from '../assets/images/quill-audits.png';
import certik from '../assets/images/certick.png'
import axios from "axios";

const Footer = () => {
    const [settings, setSettings] = useState()

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await axios.get(`${process.env.REACT_APP_ADMIN_API}/site_settings`)
                setSettings(res.data.data)
            } catch (error) {
            }
        }
        fetchData()
    }, [])

    return <>
        <footer>
            <div className="footer-header">
                <div className="container-fluid custom-block">
                    <div className="row">
                        <div className="col-lg-6 mb-lg-0 mb-4">
                            <figure className="footer-mb footer-logo">
                                <img src={Logo} alt="sitelogo" className="img-fluid" style={{height:"auto", width:"auto"}} />
                            </figure>
                            <p className="footer-mb">Muto Pad is a decentralized multi-chain fundraising platform enabling crypto entrepreneurs and investors to raise capital and promise safety to early-stage stakeholders. The Muto Tokens differ in the pumping level and provide their holders with multiple services: from staking to early access and the purchase of startup tokens.</p>
                            <ul className="social-icons">
                                
                                {settings?.twitter_link && (settings?.twitter_link !== "" && settings?.twitter_link != null) && <li><a href={`${settings?.twitter_link}`} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 50 50"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M 50.0625 10.4375 C 48.214844 11.257813 46.234375 11.808594 44.152344 12.058594 C 46.277344 10.785156 47.910156 8.769531 48.675781 6.371094 C 46.691406 7.546875 44.484375 8.402344 42.144531 8.863281 C 40.269531 6.863281 37.597656 5.617188 34.640625 5.617188 C 28.960938 5.617188 24.355469 10.21875 24.355469 15.898438 C 24.355469 16.703125 24.449219 17.488281 24.625 18.242188 C 16.078125 17.8125 8.503906 13.71875 3.429688 7.496094 C 2.542969 9.019531 2.039063 10.785156 2.039063 12.667969 C 2.039063 16.234375 3.851563 19.382813 6.613281 21.230469 C 4.925781 21.175781 3.339844 20.710938 1.953125 19.941406 C 1.953125 19.984375 1.953125 20.027344 1.953125 20.070313 C 1.953125 25.054688 5.5 29.207031 10.199219 30.15625 C 9.339844 30.390625 8.429688 30.515625 7.492188 30.515625 C 6.828125 30.515625 6.183594 30.453125 5.554688 30.328125 C 6.867188 34.410156 10.664063 37.390625 15.160156 37.472656 C 11.644531 40.230469 7.210938 41.871094 2.390625 41.871094 C 1.558594 41.871094 0.742188 41.824219 -0.0585938 41.726563 C 4.488281 44.648438 9.894531 46.347656 15.703125 46.347656 C 34.617188 46.347656 44.960938 30.679688 44.960938 17.09375 C 44.960938 16.648438 44.949219 16.199219 44.933594 15.761719 C 46.941406 14.3125 48.683594 12.5 50.0625 10.4375 Z"></path>
                                    </svg>
                                </a></li>}

                                {settings?.telegram_link && (settings?.telegram_link !== "" && settings?.telegram_link != null) && <li>
                                    <a href={`${settings?.telegram_link}`} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 448 512"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path>
                                    </svg>
                                </a>
                                </li>}
                                 {settings?.medium_link && (settings?.medium_link !== "" && settings?.medium_link != null) && <li><a href={`${settings?.medium_link}`} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        viewBox="0 0 50 50"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M45,4H5C4.448,4,4,4.448,4,5v40c0,0.552,0.448,1,1,1h40c0.552,0,1-0.448,1-1V5C46,4.448,45.552,4,45,4z M40,13.5 l-1.821,2.197C38.064,15.811,38,15.965,38,16.125V32.75c0,0.16,0.064,0.314,0.179,0.428L40,35.546V36H30v-0.454l2.821-2.368 C32.936,33.064,33,32.91,33,32.75V17.879L24.848,36h-0.001h-1.543l0,0L15,18.375v13.108c0,0.22,0.076,0.433,0.215,0.605L18,35.546 V36h-8v-0.454l2.783-3.438C12.923,31.936,13,31.721,13,31.5V16.388c0-0.142-0.05-0.279-0.142-0.388L11,13.5V13h7.097l7.624,16.183 L33.002,13H40V13.5z" />
                                    </svg>
                                </a></li>}
                                {settings?.aap_link && (settings?.aap_link !== "" && settings?.aap_link != null) && <li><a href={`${settings?.aap_link}`} target="_blank" rel="noopener noreferrer">
                                <svg width="24px" fill="#0cbcfc" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"><path d="M10,2H3C2.4,2,2,2.4,2,3v7c0,0.6,0.4,1,1,1h7c0.6,0,1-0.4,1-1V3C11,2.4,10.6,2,10,2z M10,13H3c-0.6,0-1,0.4-1,1v7c0,0.6,0.4,1,1,1h7c0.6,0,1-0.4,1-1v-7C11,13.4,10.6,13,10,13z M21,2h-7c-0.6,0-1,0.4-1,1v7c0,0.6,0.4,1,1,1h7c0.6,0,1-0.4,1-1V3C22,2.4,21.6,2,21,2z M21,13h-7c-0.6,0-1,0.4-1,1v7c0,0.6,0.4,1,1,1h7c0.6,0,1-0.4,1-1v-7C22,13.4,21.6,13,21,13z"/></svg>
                                </a></li>}
                                {settings?.poscast_link && (settings?.poscast_link !== "" && settings?.poscast_link != null) && <li><a href={`${settings?.poscast_link}`} target="_blank" rel="noopener noreferrer">
                                <svg width="24px" height="24px" fill="#0cbcfc" viewBox="0 0 24 24" id="6df8afbb-eb72-4e8f-bccd-cc7effffbdd4" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg"><title>prime</title><g id="ab9a8af1-3c3e-4a8e-b36d-c4c65eca2fa6" data-name="mic"><path d="M22,11H18A6,6,0,0,1,6,11H2a10,10,0,0,0,8,9.8V24h4V20.8A10,10,0,0,0,22,11Z"/><path d="M16,4A4,4,0,0,0,8,4v7H8a0.19,0.19,0,0,0,0,.12,4,4,0,0,0,4,3.94,3.92,3.92,0,0,0,4-3.91A0.37,0.37,0,0,0,16,11h0V4Z"/></g></svg>
                                </a></li>}
                                

                                {settings?.linkedin_link && (settings?.linkedin_link !== "" && settings?.linkedin_link != null) && <li><a href={`${settings?.linkedin_link}`} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        stroke="#0cbcfc"
                                        fill="#0cbcfc"
                                        strokeWidth="0"
                                        viewBox="0 0 24 24"
                                        height="24px"
                                        width="24px"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                          <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"/>

                                    </svg>
                                </a></li>}
                                {settings?.youtube_link && (settings?.youtube_link !== "" && settings?.youtube_link != null) && <li><a href={`${settings?.youtube_link}`} target="_blank" rel="noopener noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#0cbcfc" height="35" width="35" viewBox="-35.20005 -41.33325 305.0671 247.9995"><path d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85" fill="#0cbcfc"/><path d="M93.333 117.559l61.333-34.89-61.333-34.894z" fill="#050D59"/></svg>
                                </a></li>}
                                {settings?.website_link && (settings?.website_link !== "" && settings?.website_link != null) && <li><a href={`${settings?.website_link}`} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 384 512"
                                        height="0.8em"
                                        width="0.8em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm57.1 120H305c7.7 0 13.4 7.1 11.7 14.7l-38 168c-1.2 5.5-6.1 9.3-11.7 9.3h-38c-5.5 0-10.3-3.8-11.6-9.1-25.8-103.5-20.8-81.2-25.6-110.5h-.5c-1.1 14.3-2.4 17.4-25.6 110.5-1.3 5.3-6.1 9.1-11.6 9.1H117c-5.6 0-10.5-3.9-11.7-9.4l-37.8-168c-1.7-7.5 4-14.6 11.7-14.6h24.5c5.7 0 10.7 4 11.8 9.7 15.6 78 20.1 109.5 21 122.2 1.6-10.2 7.3-32.7 29.4-122.7 1.3-5.4 6.1-9.1 11.7-9.1h29.1c5.6 0 10.4 3.8 11.7 9.2 24 100.4 28.8 124 29.6 129.4-.2-11.2-2.6-17.8 21.6-129.2 1-5.6 5.9-9.5 11.5-9.5zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
                                    </svg>
                                </a></li>} 
                            </ul>
                        </div>
                        <div className='col-lg-4 mb-0 offset-lg-2'>
                            <div className='row'>
                                <div className="col-lg-6 mb-lg-0 mb-3">
                                    <h3 className="footer-mb text-capitalize">general</h3>
                                    <ul className="footer-links">
                                        <li><Link to="/faqs">FAQ</Link></li>
                                        <li><a href={process.env.REACT_APP_Web_link}>Apply for IDO</a></li>
                                        <li className=""><Link to="/privacy">Privacy Policy</Link></li>
                                        <li className=""><Link to="/tos">Terms of Use</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 mb-lg-0 mb-3">
                                    <h3 className="footer-mb text-capitalize">Audits</h3>
                                    <ul className="footer-links">
                                        <li><Link to="/"><img src={quil} className='img-fluid' alt='Quil Audits' style={{height:"auto", width:"auto"}} /></Link></li>
                                        <li><Link to="/"><img src={certik} className='img-fluid' alt='CERTIK' style={{height:"auto", width:"auto"}} /></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container-fluid custom-block">
                    <div className="footer-bottom-inner">
                        <div className="row align-items-center">
                            <div className="col-lg-9 col-md-6 mb-0">
                                <p className="copyright mb-sm-0 mb-2">© Copyright MutoPad Finance 2022. All rights reserved.</p>
                            </div>
                            <div className="col-lg-3 col-sm-6 mb-0 md-none">
                                <ul className="footer-links">
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>;
};

export default Footer;
