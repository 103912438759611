import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DefualtImageSetter } from "../../utils/globalHelpers";
import { TailSpin } from "react-loader-spinner";
/* ICONS */
import Medium from "../../assets/images/icon-2.png";
import Telegram from "../../assets/images/icon-1.png";
import Twitter from "../../assets/images/twiiter.png";
import Website from "../../assets/images/icon-4.png";
import Discord from "../../assets/images/horn.svg";

function IdoCards({ idoArr, currentIdoLength }) {
  const socialLinks = [Twitter, Discord, Medium, Telegram, Website];

  const [loadMoreIdo, setLoadMoreIdo] = useState(4);

  return (
    <div className="row">
      {idoArr.slice(0, loadMoreIdo).map((item, index) => {
        return (
          <div
            className="col-lg-3 col-md-4 col-sm-6 col-12 mb-md-4 mb-3"
            key={index}
          >
            <Link
              to={`/${"pool"}/${item.idoAddress}`}
              className="card mb-3"
              //   state={{ item: item }}
            >
              <div className="d-flex justify-content-between flex-column h-100">
                <div className="d-block">
                  <div
                    className="d-flex align-items-lg-start "
                    animation="wave"
                  >
                    <figure className="mb-0 game-img">
                      <img
                        className="img-fluid profile"
                        src={item.logoHash}
                        alt="Gammes"
                        onError={DefualtImageSetter}
                      />
                    </figure>

                    <div className="w-100">
                      <p className="card-title s-line first mb-1">
                        {item.name}
                      </p>
                      <div className="d-flex flex-column">
                        <p className="text-capitalize s-line second mb-1">
                          {item.name}
                        </p>
                        {/* <p className='text-capitalize fw-bold ido-bold'>starts: <span className='date-color'>{item.startDate}</span> </p> */}
                        {/* <a href="#" href="#" className='tag-btn text-uppercase'>upcoming</a> */}
                      </div>
                    </div>
                  </div>
                  <div className="social-icon-bar">
                    <ul>
                      <li>
                        <figure className="mb-0">
                          <img
                            className="img-fluid"
                            src={`${socialLinks[0]}`}
                            alt="Gammes"
                          />
                        </figure>
                      </li>
                      <li>
                        <figure className="mb-0">
                          {/* <img
                            className="img-fluid"
                            src={`${socialLinks[1]}`}
                            alt="Gammes"
                          /> */}
                          <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 276 212"
                              height="30px"
                              width="30px"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"></path>
                            </svg>
                        </figure>
                      </li>
                      <li>
                        <figure className="mb-0">
                          <img
                            className="img-fluid"
                            src={`${socialLinks[2]}`}
                            alt="Gammes"
                          />
                        </figure>
                      </li>
                      <li>
                        <figure className="mb-0">
                          <img
                            className="img-fluid"
                            src={`${socialLinks[3]}`}
                            alt="Gammes"
                          />
                        </figure>
                      </li>
                      <li>
                        <figure className="mb-0">
                          <img
                            className="img-fluid"
                            src={`${socialLinks[4]}`}
                            alt="Gammes"
                          />
                        </figure>
                      </li>
                    </ul>
                  </div>
                  <div className="progress-bar">
                    <div className="prog-text d-flex ">
                      <p className="mb-1">
                        {item.remainingHours.day} Days and{" "}
                        {item.remainingHours.hours} Hours
                      </p>
                    </div>
                    <div className="mb-1">
                      <ProgressBar
                        now={item.salePercent}
                        style={{ color: "pink" }}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="game-price">0 {item.name}</p>
                      <p>{/* {item.initialSupply} {item.name} */}</p>
                    </div>
                    {/* <p className='text-capitalize fw-bold ido-bold'>starts: <span className='date-color'>{item.startDate}</span> </p> */}
                  </div>
                </div>
                <div>
                  <p className="text-capitalize fw-bold ido-bold">
                    starts: <span className="date-color">{item.startDate}</span>{" "}
                  </p>
                  <div className="card-footer">
                    <div className="card-footer-content">
                      <ul>
                        {/* <li>
                      <p className='text-capitalize fw-bold ido-bold'>starts</p>
                      <div className='d-flex'>
                        <strong className='d-block feature-price blue'>
                          {item.startDate}
                        </strong>
                        <span className='text-capitalize'>
                          {time.hour}:{time.min} UTC
                        </span>
                      </div>
                    </li> */}
                        <li>
                          <p className="text-capitalize fw-bold ido-bold">
                            price
                          </p>
                          <div className="d-flex align-items-baseline">
                            <strong className="d-block feature-price purple me-2">
                              {item.price}
                            </strong>
                            <span className="text-capitalize">
                              {" "}
                              <p>= {item.price} $</p>
                            </span>
                          </div>
                        </li>
                        <li>
                          <p className="text-capitalize fw-bold ido-bold">
                            total raise
                          </p>
                          <div>
                            <strong className="d-block feature-price pink">
                              ${item.totalRaised}
                            </strong>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
      {currentIdoLength !== idoArr.length && (
        <div className="text-center d-flex justify-content-center mb-2">
          <TailSpin
            height="50"
            width="50"
            color="#46bdf4"
            ariaLabel="loading"
          />
        </div>
      )}
      {currentIdoLength > loadMoreIdo && (
        <div className="d-flex justify-content-center align-items-center">
          {" "}
          <button
            className="light-blue-btn"
            onClick={() => setLoadMoreIdo(loadMoreIdo + 4)}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
}

export default IdoCards;
