import React, { useEffect } from 'react'
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip, Legend} from 'recharts'
import useFetchIdoCounts from '../../../CustomHooks/FetchIdoCounts';

const Barchart = () => {
  const [totalIdo] = useFetchIdoCounts();
  const {live,upcoming,ended} = totalIdo;
  const totalPools = totalIdo?.live+totalIdo?.upcoming+totalIdo?.ended
    const data =[
      {
        "totalpools":`TotalPools:${totalPools}`,
        "live":`${live}`,
        "upcoming":`${upcoming}`,
        "ended":`${ended}`
      }
    ]
 
    useEffect(() => {
        // eslint-disable-next-line
     }, [totalIdo]);

  return(  
  <div>
    <BarChart width={730} height={250} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="totalpools" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="live" fill="#4287f5" />
      <Bar dataKey="upcoming" fill="#42f56f" />
      <Bar dataKey="ended" fill=" #eded18" />
    </BarChart>
  </div>)
}

export default Barchart;