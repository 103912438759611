import React from 'react'

const Banner = () => {

  return (
    <>
      <section className='hero-banner'>
        <div className='container-fluid custom-block'>
          <div className='banner-text'>
            <div className='text-wrapper'>
              <div className='text-wrapper'>
                <span className='banner-span text-uppercase'>
                MULTI-CHAIN 
                </span>
                <h1 className='banner-h1 text-uppercase'>Muto LAUNCHPAD</h1>
              </div>
              <p className='light-p'>
              Bringing high-quality blockchain projects to crypto investors in the fastest and safest way with expanded market access.  
              </p>
              {/* <p className='p'>
                Stake MutoPad tokens to get early-access to promising projects.
              </p> */}
            </div>
            <div className='block-btn text-wrapper'>
              <a
                className='d-inline shadow-btn text-capitalize'
                href={process.env.REACT_APP_Web_link}
              >
                Apply To Launch
              </a>
              <a
                className='d-inline blue-outline-btn text-capitalize'
                href='https://t.me/metamuto'
                target="_blank"
                rel="noopener noreferrer"
              >
                telegram
              </a>
            </div>
          </div>
        </div>
        <section className='market-cap'>
          <div className='container-fluid custom-block'>
              <div className='market-cap-inner m-auto'>
              <div className="titles-sett card-header justify-content-center">
                  <h2 className="text-shadow text-center">MUTO PAD – You Can Never Be In Loss!  </h2>
              </div>
                <blockquote>
                 <div>
                 <div className="titles-sett card-header py-0 mt-3 justify-content-center">
                    <label className="form-label purple text-center">The future of investing in blockchain projects powered by a decentralized multi-chain platform</label>
              </div>
                 <p className='text-white qoute-p mb-3 my-2'> Looking to get into the world of cryptocurrency investment/fundraising? MutoPad is just the platform for you! Featuring an industry-leading launchpad with pooling, guaranteed allocations and more, MutoPad is a decentralized platform on the Ethereum blockchain that’s a perfect way to get started with cryptocurrency investment.</p>
                  <p className='text-white qoute-p'>
                  Based on the crowdfunding technique, this innovative launchpad will enable cryptocurrency projects to introduce native coins/tokens through DEXs (decentralized exchanges).  And with our Initial Dex Offering (IDO), you can be sure to get in on the ground floor of some of the hottest new projects out there. So why wait? Sign up today and see how MutoPad can help you take your first steps into the exciting crypto world! 
                  </p>
                 </div>
                </blockquote>
                </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default Banner
