import { ethers } from 'ethers'
export function RPCConnector (a, b) {}
export function TimeConverter (UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000)
  let time = {}
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  var year = a.getFullYear()
  var month = months[a.getMonth()]
  var date = a.getDate()
  var hour = a.getHours()
  var min = a.getMinutes()
  // var sec = a.getSeconds()

  var time1 = date + ' ' + month + ' ' + year
  time.month = month
  time.hour = hour
  time.min = min
  time.date = date

  return time1
}

export async function FetchSigner (address, Abi) {
  if (window.ethereum !== undefined) {
    const provider = new ethers.providers.Web3Provider(window.ethereum)

    const signer = await provider.getSigner()

    var contract = new ethers.Contract(address, Abi, signer)
    return contract
  } else {
    // console.log('Error Metamask is not installed')
  }
}
export function DefualtImageSetter (e) {
  e.target.src =
  process.env.REACT_APP_DEFAULT_LOGO_FOR_POOLS
}
export async function CustomRpcProvider (address, Abi, network) {
  return new ethers.Contract(address, Abi)
}
