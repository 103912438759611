import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import useFetchIdoCounts from "../../../CustomHooks/FetchIdoCounts";
const PieChart = () => {
    const [totalIdo] = useFetchIdoCounts();
    const [chartData, setChartData] = useState({series:[], labels:[]});
   
    const pieChartOptions = {
       series: chartData.series,
       options: {
          labels: chartData.labels,
          chart: {
             type: 'donut'
          },
          responsive: [{
             breakpoint: 480,
             options: {
                chart: {
                   width: 200
                },
             }
             , legend: {
                position: 'bottom'
             }
          }]
       },
    }
    const fetchPoolData = async () => {
        let dummyseries=[];
        let dummylabels=[]
        for (const label in totalIdo) {
            dummylabels.push(label);
            dummyseries.push(totalIdo[label])
        }
          await setChartData({
                ...chartData, series:dummyseries, labels:dummylabels
            })
    };

    useEffect(() => {
        fetchPoolData();
        // eslint-disable-next-line
     }, [totalIdo]);
 
    return (
       <>
          <div className='circle-chart'>
             <Chart options={pieChartOptions.options} series={pieChartOptions.series} type="donut" height={300} width={500} />
          </div>
       </>
    )
 }
 
 export default PieChart