import React, {useLayoutEffect } from "react";
// import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import PieChart from "./Chart"
import { Link } from 'react-router-dom'
import Barchart from "./Barchart";
import "./Chart.css";
 
// import Table from "react-bootstrap/Table";
// import { Badge } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faExternalLinkAlt, faTimes } from "@fortawesome/free-solid-svg-icons";

// import { createClient } from "urql";
function BannerAdmin() {
  // const [poolAmount, setPoolAmount] = useState(0);
  // const API_URL = process.env.REACT_APP_SUBGRAPH_API_LATEST_BSC;
  // const API_URL_ETH = process.env.REACT_APP_SUBGRAPH_API_LATEST_ETH;
  const FetchGraphCount = async (id) => {
    // const tokensQuery = `{
    //   pools 
          
    //       {
    //         poolId
    //       }
          
            
    //       }`;

    // const client2 = createClient({
    //   url: API_URL,
    // });
    // const clientETH = createClient({url: API_URL_ETH,});

    // const data2 = await client2.query(tokensQuery).toPromise();

    // const dataETH = await clientETH.query(tokensQuery).toPromise();

    // if (data2.data.pools !== null && data2.data.pools !== undefined) {
    //   setPoolAmount(
    //     5
    //     );
    // }
  };
  useLayoutEffect(() => {
    FetchGraphCount();
  });
  // TABLE ARRAY
  // const Pools = [
  //   [
  //     { id: 1, poolName: "pool" },
  //     { id: 2, poolName: "network" },
  //     { id: 3, poolName: "pooldata3" },
  //     { id: 4, poolName: "pooldata4" },
  //     { id: 5, poolName: "pooldata5" },
  //     { id: 6, poolName: "pooldata6" },
  //     { id: 7, poolName: "pooldata7" }
  //   ],
  //   [
  //     { id: 1, poolName: "pooldata1" },
  //     { id: 2, poolName: "pooldata2" },
  //     { id: 3, poolName: "pooldata2" },
  //     { id: 4, poolName: "pooldata4" },
  //     { id: 5, poolName: "pooldata2" },
  //     { id: 6, poolName: "pooldata4" },
  //     { id: 7, poolName: "pooldata4" }
  //   ],
  //   [
  //     { id: 1, poolName: "pooldata" },
  //     { id: 2, poolName: "pooldata3" },
  //     { id: 3, poolName: "pooldata3" },
  //     { id: 4, poolName: "pooldata3" },
  //     { id: 5, poolName: "pooldata5" },
  //     { id: 6, poolName: "pooldata6" },
  //     { id: 7, poolName: "pooldata7" }
  //   ],
  // ];
  return (
    <div className="mutopad-w-100">
      <div className="content">
        {/* TABLE */}
        <div className="manage-pools-pg">
          <Card className="pt-lg-4 pt-3 h-100" id="style-6">
            {/* CARD ROW */}
            <div className="row">
              <div className="col-md-12">
                <div className="panel dark-theme">
                  <div className="panel-body dashboard-cards">
                    <div className="row mb-3 shadow-cards justify-content-between align-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                        <Link to="#">
                            <div className="widget-metric_6 animate bg-primary box-primary-shadow">
                            <div className="right">
                              <span className="value">
                                  Manage Pools
                                {/* Number Of Pools - {poolAmount} */}
                              </span>
                              {/* <span className='title'>Users</span> */}
                            </div>
                          </div>
                        </Link>
                        </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                        <Link to="/admin/faqs">
                          <div className="widget-metric_6 animate blue-yellow box-primary-shadow">
                            <div className="right">
                              <span className="value">Manage Faqs</span>
                              {/* <span className="title">Users</span> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                        <Link to="/admin/cmspages">
                        <div className="widget-metric_6 animate blue-light box-primary-shadow">
                            <div className="right">
                              <span className="value">CMS Pages</span>
                              {/* <span className="title">2</span> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                    
                      {/* <div className="col-md-4 col-12 mb-3">
                                            <div className="widget-metric_6 animate blue-yellow box-primary-shadow">
                                                <div className="right">
                                                    <span className="value">Total Stakings</span>
                                                    <span className="title">Users</span>
                                                </div>
                                            </div>
                                        </div> */}
                      {/* <div className="col-md-4 col-12 mb-3">
                                            <div className="widget-metric_6 animate blue-light box-primary-shadow">
                                                <div className="right">
                                                    <span className="value">Admin Users</span>
                                                    <span className="title">2</span>
                                                </div>
                                            </div>
                                        </div> */}
                    </div>
                  </div>
                  <h1 className="pool-label">Pools Stats</h1>
                  <div className="chart-div">
                    <PieChart/>
                    <Barchart/>
                  </div>
                </div>
              </div>
            </div>
            {/*  END CARD ROW*/}
            {/* <Card.Header className="titles-sett">
              <h2 className="text-shadow">Top Pools - {poolAmount} </h2>
            </Card.Header>
            <Card.Body>
              {/* <div className='table-responsive manage-pools'>
                <Table>
                  <thead>
                  </thead>
                  <tbody>
                        {
                          Pools.map((pool, index) => {
                            return (
                              <tr className='text-capitalize' key={index}>
                              {
                                pool.map((value, vIndex) => {
                                  return (
                                    <td key={vIndex}>
                                      {value.poolName}
                                    </td>
                                )
                              }) 
                              }
                              </tr>
                            )
                          })}
                  </tbody>
                </Table>
              </div> */}

              {/* <div className="table-responsive manage-pools">
                <Table>
                  <thead>
                    <tr>
                      <th>Pool</th>
                      <th>Name</th>
                      <th>Network</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{display: "none"}}>
                      <td>
                        <Link to="" style={{ color: "white" }}>
                          <FontAwesomeIcon
                            className="del-icon"
                            icon={faExternalLinkAlt}
                          />
                          0x...
                        </Link>
                      </td>
                      <td>Nest Arcade</td>

                      <td>BSC</td>
                      <td>11 Mar 2022</td>
                      <td>17 Mar 2022</td>
                      <td>
                        <Badge className="px-2 py-1" bg="info">
                          Active
                        </Badge>

                      </td>
                      <td>
                        <button className="del_btn">
                          <FontAwesomeIcon
                            className="del-icon"
                            icon={faTimes}
                          />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div> 
            </Card.Body>*/}
          </Card>

        </div>
      </div>
    </div>
  );
}
export default BannerAdmin;
