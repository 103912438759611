import { useEffect, useState, useCallback } from "react";
import { createClient } from "urql";
function useFetchAllOrder() {
  // import data from '../utils/api'
  // import { ethers } from 'ethers'
  const [time] = useState({});
  const [AllOrders, setAllOrders] = useState([]);
  const API_URL_BSC = process.env.REACT_APP_SUBGRAPH_API_LATEST_BSC;
  const API_URL_ETH = process.env.REACT_APP_SUBGRAPH_API_LATEST_ETH;
  const timeConverter = useCallback(
    (UNIX_timestamp) => {
      // var a = new Date(UNIX_timestamp * 1000);
      var a = new Date(UNIX_timestamp * 1000);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      // var sec = a.getSeconds()

      var time1 = date + " " + month + " " + year;
      time.month = month;
      time.hour = hour;
      time.min = min;
      time.date = date;
      return time1;
    },
    [time]
  );
  const FetchDataSetter = useCallback(
    (idoData) => {
      async function FetchHelper(idoData) {
        let dummyArr = [];
        // Data Parsing and Conversion appear here
        for (let i = 0; i < idoData.length; i++) {
          const {
            poolId,
            poolingToken,
            network,
            startDate,
            endDate,
            name,
            isDeleted
          } = idoData[i];
          await Math.round(+new Date() / 1000);
          const timinStart = timeConverter(startDate);
          const timinEnd = timeConverter(endDate);

          let dummyObj = {
            poolId: poolId,
            name: name,
            poolingToken: poolingToken,
            isDeleted: isDeleted,
            startDate: timinStart,
            endDate: timinEnd,
            network: network,
          };
          dummyArr.push(dummyObj);
          setAllOrders((AllOrders) => [...AllOrders, dummyObj]);
        }

        // setAllOrders((AllOrders) => [...AllOrders, dummyArr]);

        // setAllOrders(dummyArr);
      }

      FetchHelper(idoData);
    },
    // eslint-disable-next-line
    [timeConverter]
  );
  const fetchGraphData = useCallback(async () => {
    const tokensQuery = `{
      pools{
        name
        startDate
        endDate
        price
        totalRaised
        poolingToken
        logoHash
        isDeleted
        poolId
        infoHash
        blockTimestamp
        transactionHash
        poolingTokenDecimal
        network
        twitter
        discord
        medium
        telegram
        website
      }
      }`;
    const clientBSC = createClient({
      url: API_URL_BSC,
    });

    const clientETH = createClient({
      url: API_URL_ETH,
    });
    const dataBSC = await clientBSC.query(tokensQuery).toPromise();

    const dataETH = await clientETH.query(tokensQuery).toPromise();

    if (dataBSC.data !== null && dataBSC.data !== undefined) {
      FetchDataSetter(dataBSC.data.pools);
    }
    if (dataETH.data !== null && dataETH.data !== undefined) {
      FetchDataSetter(dataETH.data.pools);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [API_URL_BSC]);

  useEffect(() => {
    fetchGraphData();
  }, [fetchGraphData]);
  return [AllOrders];
}

export default useFetchAllOrder;
