import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import { Accordion } from "react-bootstrap";
import Footer from "../../components/footer";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";

function Faqs() {
  const [loader, setLoader] = useState(false);
  const [faqs, setFaqs] = useState({
    id: 0,
    title: "",
    description: "",
  });
  useEffect(() => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_ADMIN_API}/faqs`)
      .then((res) => {
        setFaqs(res.data.data);
        setLoader(false);
        // setAlert('')
      })
      .catch((err) => {
      });
  }, []);
  return (
    <>
      {loader ? (
        <div className="faq-spin">
        <TailSpin height="50" width="50" color="#46bdf4" ariaLabel="loading" /> </div>
      ) : (
        <div className="container">
          <div className="section_padding">
            <div className="terms-page bg-transparent">
              <div className="page-heading my-lg-4 my-2">
                <h2 className="text-white text-capitalize mt-4 text-center">
                  FAQs
                </h2>
              </div>
              {faqs.length > 0 &&
                faqs.map((faq, ind) => (
                  <Accordion className="ido-front-side-faqs" key={ind}>
                    <Accordion.Item>
                      <Accordion.Header>
                        <h4 className="card-title text-white mb-0">
                          {faq.title}
                        </h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="card-text" dangerouslySetInnerHTML={{ __html: faq.description }}></p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default Faqs;
